function PointerDown() {
  return (
    <div className="pointer">
      <div className="arrow">&or;</div>
      <div className="arrow">&or;</div>
      <div className="arrow">&or;</div>
    </div>
  );
}

export default PointerDown;
